/// <reference path="o365.pwa.declaration.sw.routehandlers.api.file.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const { ApiFileStrategy } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.strategies.api.file.strategy.d.ts')>("o365.pwa.modules.sw.strategies.api.file.strategy.ts");

    function initializeApiFileRouteHandler() {
        self.o365.registerRoute(
            new RegExp('\/pwa\/api\/file\/view\/*'),
            new ApiFileStrategy({
                apiFileRoute: 'VIEW'
            }),
            'GET'
        );
        self.o365.registerRoute(
            new RegExp('\/pwa\/api\/file\/download\/*'),
            new ApiFileStrategy({
                apiFileRoute: 'DOWNLOAD'
            }),
            'GET'
        );
        self.o365.registerRoute(
            new RegExp('\\/nt\\/?api\\/file\\/upload(\\/.*)?$'),
            new ApiFileStrategy({
                apiFileRoute: 'UPLOAD'
            }),
            'POST'
        );
        self.o365.registerRoute(
            new RegExp('\/api\/file\/chunkupload\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(?:\/|$)'),
            new ApiFileStrategy({
                apiFileRoute: 'OLD-CHUNK-UPLOAD'
            }),
            'POST'
        );
        self.o365.registerRoute(
            new RegExp('\/api\/file\/chunkupload\/upload\/*'),
            new ApiFileStrategy({
                apiFileRoute: 'CHUNK-UPLOAD'
            }),
            'POST'
        );
        self.o365.registerRoute(
            new RegExp('\/api\/file\/chunkupload\/initiate\/*'),
            new ApiFileStrategy({
                apiFileRoute: 'CHUNK-INITIATE'
            }),
            'POST'
        );
        self.o365.registerRoute(
            new RegExp('\/api\/file\/chunkupload\/setcrc32\/*'),
            new ApiFileStrategy({
                apiFileRoute: 'CHUNK-SETCRC'
            }),
            'POST'
        );
        self.o365.registerRoute(
            new RegExp('\/api\/file\/chunkupload2\/*'),
            new ApiFileStrategy({
                apiFileRoute: 'CHUNK-UPLOAD'
            }),
            'POST'
        );
        self.o365.registerRoute(
            new RegExp('\/nt\/?api\/file\/uploadprogress\/*'),
            new ApiFileStrategy({
                apiFileRoute: 'UPLOAD-PROGRESS'
            }),
            'POST'
        );
        self.o365.registerRoute(
            new RegExp('\/pwa\/api\/view-pdf\/.*'),
            new ApiFileStrategy({
                apiFileRoute: 'PDF-VIEW'
            }),
            'GET'
        );
        self.o365.registerRoute(
            new RegExp('\/pwa\/api\/download-pdf\/*'),
            new ApiFileStrategy({
                apiFileRoute: 'PDF-VIEW'
            }),
            'GET'
        );
    }

    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.routehandlers.api.file.d.ts')>({ initializeApiFileRouteHandler });
})()
